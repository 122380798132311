<template>
 <div class="full-width-table">
  <sdCards bodypadding="0px" title="Recente orders">
   <div class="table-responsive">
    <a-table :columns="ordersColumns" :dataSource="tableData" :pagination="false"/>
   </div>
  </sdCards>
 </div>
</template>
<script>
import {computed, defineComponent} from 'vue';
import VueTypes from "vue-types";
import {useRouter} from "vue-router";
import {useAbility} from "@casl/vue";

const ordersColumns = [
 {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: 'Status',
  dataIndex: 'statusName',
  key: 'statusName',
 },
 {
  title: 'Product',
  dataIndex: 'productName',
  key: 'productName',
 },
 // {
 //  title: 'Organisatie',
 //  dataIndex: 'organizationName',
 //  key: 'organizationName',
 // },
 {
  title: 'Actions',
  dataIndex: 'action',
  key: 'action',
  width: '90px',
 },
];

const RecentOrder = defineComponent({
 name: 'RecentOrder',
 props: {
  orders: VueTypes.array.def([]),
 },
 setup(props) {
  const {can} = useAbility();
  const router = useRouter();
  const handleEdit = (id) => {
   router.push({name: 'orderEdit', params: {id: id}});
  };

  const tableData = computed(() =>
   props.orders
    ? props.orders.map((value) => {
     const {id, productName, relationFullName, statusName, statusColor, organizationName, senderName} = value;
     return {
      key: id,
      id: <small>{id}</small>,
      productName: (
       <div><small>{productName}</small><br/><small>{relationFullName}</small></div>
      ),

      statusName: (<span className="status-text" style={{
        color: 'white',
        backgroundColor: `${statusColor}`
       }}>{statusName}</span>
      ),
      organizationName: (
       <div><small>{organizationName}</small><br/><small>{senderName}</small></div>
      ),
      action: (
       <div>
        {can('view', 'order') ? (
         <sdButton onClick={() => handleEdit(id)} to="#" type="default"
                   shape="circle">
          <sdFeatherIcons type="eye" size="16"/>
         </sdButton>
        ) : ('')}
       </div>
      ),
     };
    })
    : [],
  );

  return {
   tableData,
   ordersColumns,
  };
 },
});

export default RecentOrder;
</script>
